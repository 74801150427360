<template>
  <div>
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="/elevator-iot/data/fault/page">
      <el-button

        slot="btn"
        :disabled="status==='loading'"
        size="mini"
        style="height: 26px;padding: 0 10px"
        type="primary"
        :loading="loading"
        @click="exportExcelData">
        {{$l("common.search", "导出")}}
      </el-button>
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search label="注册代码">
            <el-input v-model.trim="filter.regCode" clearable></el-input>
          </vm-search>
          <vm-search label="终端编号">
            <el-input v-model.trim="filter.deviceNo" clearable></el-input>
          </vm-search>

          <vm-search label="故障代码">
            <el-input v-model.trim="filter.faultCode" clearable></el-input>
          </vm-search>
          <vm-search label="故障类型">
            <vm-dict-select v-model="filter.type" type="faultType"></vm-dict-select>
          </vm-search>
          <vm-search label="楼盘">
            <el-input v-model="filter.realEstateName" type="faultType"></el-input>
          </vm-search>
          <vm-search label="时间范围">
            <el-date-picker
              v-model="filter.seTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="handleTime">
            </el-date-picker>
          </vm-search>
        </div>
      </template>

      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="deviceNo" label="终端编号" align="center"></el-table-column>
      <el-table-column prop="regCode" label="注册代码" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="$refs.elevatorDetail.open(scope.row.elevatorId)">{{scope.row.regCode}}</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="realEstateName" label="楼盘名称" align="center"></el-table-column>
      <el-table-column prop="elevatorName" label="电梯名称" align="center"></el-table-column>
      <el-table-column prop="faultCode" label="故障代码" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="goToBasicFault(scope.row)">{{scope.row.faultCode}}</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="faultName" label="故障名称" align="center"></el-table-column>
      <el-table-column prop="type" label="故障类型" align="center"></el-table-column>
      <el-table-column label="故障时间" align="center">
        <template slot-scope="scope">
          {{dayjs(scope.row.faultTime).format('YYYY-MM-DD HH:mm:ss')}}
        </template>
      </el-table-column>
      <el-table-column prop="time" label="数据保存时间" align="center"></el-table-column>
    </vm-table>
    <fault-detail ref="faultDetail" @select="buildingSelect"></fault-detail>
    <elevator-detail ref="elevatorDetail" @save-success="getList(-1)"></elevator-detail>
  </div>
</template>
<script>
  import FaultDetail from "@/views/basic/FaultDetail";
  import elevatorDetail from "@/views/elevator/ElevatorDetail";
  import {export2Excel} from "@/util";
  import moment from "moment";

  export default {
    components: {FaultDetail,elevatorDetail},
    props:{
      menus:{
        type: Array,
        default:function() {
          return [];
        },
      }},
    data() {
      return {
        filter: {
          regCode: "",
          deviceNo: "",
          faultCode: "",
          startTime: "",
          endTime: "",
          seTime: "",
        },
        loading:false,
        os:localStorage.getItem("os"),
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      exportExcelData() {
        const title={
          deviceNo:"终端编号",
          realEstateName:"楼盘名称",
          elevatorName:"电梯名称",
          regCode:"注册代码",
          faultCode:"故障代码",
          faultName:"故障名称",
          type:"故障类型",
          faultTime:"故障时间",
        };
        this.loading=true;
        this.$http.get("elevator-iot/data/fault/page",{...this.filter,pageSize:300000}).then(res=>{
          this.loading=false;
          export2Excel(title,res.records.map(item=>{return {...item,faultTime:moment(item.faultTime).format("YYYY-MM-DD HH:mm:ss")};}),`故障记录-${res.total}条-${moment(new Date()).format("YYYYMMDDhhmmss")}`);
        }).catch(()=>{
          this.loading=false;
        });
      },
      handleTime() {
        console.log(this.filter.seTime);
        if(this.filter.seTime !=null) {
          this.filter.startTime = this.filter.seTime[0];
          this.filter.endTime = this.filter.seTime[1];
        }
      },
      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
      },
      goToBasicFault(row) {
        if (this.os==="new") {
          let obj ={};
          this.menus.forEach(item=>{
            if (item.menuName==="电梯故障字典") {
              obj=item;
            }
          });
          console.log(obj,88);
          // sessionStorage.setItem("iotCode",row.elevatorIot.iotDeviceName);
          this.$emit("navigator",obj);
          this.$emit("query",{faultCode:row.faultCode});
        }else {
          if (row.faultDictId) {
            this.$refs.faultDetail.open(row.faultDictId);
          }else {
            this.$router.push({path: "/basic/fault",query:{faultCode:row.faultCode}});
          }
        }

      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
